@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "DINAlternate";
  src: url("../../public/fonts/DINAlternate-Bold.ttf");
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --background-contrast: black;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: #8d8d91;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

.custom-scrollbar {
  padding-right: 16px;
  padding-bottom: 80px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 4px;

  /* outline: 1px solid slategrey; */
}

.form-body-parent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.form-body-parent form {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.text-300-12 {
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 14.52px !important;
}
.text-300-14 {
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}
.text-700-14 {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}
.text-700-16 {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 18.63px !important;
}
.text-700-18 {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21.78px !important;
}
.text-700-24 {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29.05px !important;
}

.text-500-12 {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14.52px !important;
}

.text-400-10 {
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 12.12px !important;
}

.text-400-12 {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14.52px !important;
}
.text-400-14 {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}
.text-300-16 {
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
}
.text-500-16 {
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
}
.text-400-16 {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
}
.text-500-14 {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}

.text-600-14 {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}
.text-600-16 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
}
.text-500-18 {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 21.78px !important;
}
.text-500-22 {
  font-weight: 500 !important;
  font-size: 22px !important;
  line-height: 26px !important;
}

.title-700-14 {
  font-family: "DINAlternate" !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}
.title-700-20 {
  font-family: "DINAlternate" !important;
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 23.28px !important;
}

.title-700-22 {
  font-family: "DINAlternate" !important;
  font-weight: bold !important;
  font-size: 22px !important;
  line-height: 25.61px !important;
}

.title-700-24 {
  font-family: "DINAlternate" !important;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 27.94px !important;
}

.title-700-32 {
  font-family: "DINAlternate" !important;
  font-weight: bold !important;
  font-size: 32px !important;
  line-height: 37.25px !important;
}

.text-800-14 {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
}

.lucide {
  width: 24px;
  height: 24px;
  stroke-width: 1px;
}

html,
body,
main {
  overflow: hidden;
}

nextjs-portal,
.nextjs-toast-errors-parent {
  display: none;
}

.border-spaced-dotted {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%238D8D91FF' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.filter-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%)
    contrast(119%);
}

.filter-error-text {
  filter: invert(10%) sepia(85%) saturate(4223%) hue-rotate(355deg) brightness(98%)
    contrast(114%);
}
.filter-success-text {
  filter: invert(27%) sepia(11%) saturate(6398%) hue-rotate(67deg) brightness(95%)
    contrast(105%);
}

.filter-zinc-300 {
  filter: invert(92%) sepia(4%) saturate(153%) hue-rotate(202deg) brightness(89%)
    contrast(101%);
}
.filter-grey-400 {
  filter: invert(67%) sepia(9%) saturate(217%) hue-rotate(201deg) brightness(96%)
    contrast(87%);
}
.filter-forecast-blue-readable-light {
  filter: invert(17%) sepia(81%) saturate(2875%) hue-rotate(194deg) brightness(97%)
    contrast(102%);
}
.filter-forecast-blue-readable {
  filter: invert(14%) sepia(57%) saturate(6635%) hue-rotate(197deg) brightness(92%)
    contrast(101%);
}
.filter-white {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
    contrast(102%);
}
.filter-warning-deco {
  filter: invert(73%) sepia(87%) saturate(3954%) hue-rotate(357deg) brightness(98%)
    contrast(110%);
}

.flex-truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dialog-body {
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}
/* 
@layer base {
  * {
    @apply border-border outline-ring/50;
  }
  body {
    @apply bg-background text-foreground;
  }
} */
